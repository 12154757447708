<template>
  <div class="table-responsive position-relative">
    <spinner-component v-if="isDataLoading" item-class="table-spinner-layout"></spinner-component>
    <table class="table table-striped table-hover" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in invites" :key="row.email">
          <td>
            <copy-button v-if="row.status === 'pending'" :text="row.link"></copy-button>
            {{ row.email }}
          </td>
          <td>{{ row.first_name || '-' }}</td>
          <td>{{ row.last_name || '-' }}</td>
          <td>{{ row.country || '-' }}</td>
          <td>{{ $t(`invitePage.table.status_${row.status}`) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { getInvitedListApiRequest } from '@/application/services/invite.js'
import { defineAsyncComponent, ref, onMounted } from 'vue'

export default {
  name: 'InviteListPage',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'copy-button': defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
  },
  setup() {
    const invites = ref([])
    const isDataLoading = ref(true)
    const fields = [
      'invitePage.table.email', 'invitePage.table.firstName', 'invitePage.table.lastName',
      'invitePage.table.country', 'invitePage.table.status',
    ]

    onMounted(() => {
      getInvitedListApiRequest()
        .then(({ data }) => {
          isDataLoading.value = false
          invites.value = data.data
          console.log(data.data)
        })
    })

    return {
      invites,
      isDataLoading,
      fields,
    }
  },
}
</script>
